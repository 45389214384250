import React, { Component } from "react";

import Layout from "../layouts/zh";
import SEO from "../components/seo";
import SignUp from "../services/SignUp";
import SignUpLang from "../data/static-pages/pages/signup/zh";
import { withAuthenticationRequired } from "@auth0/auth0-react";

const isAuth0Enabled = process.env.GATSBY_ENABLE_AUTH0 === "true";

class SignUpPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.urlRedirect = "/zh/verify-account";
    this.termsUrl = "/zh/terms-and-conditions-8";
    this.langKey = this.props.pageContext.langKey;
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <SEO title="Sign Up " />

        <SignUp
          location={this.props.location}
          urlRedirect={this.urlRedirect}
          langData={SignUpLang}
          langKey={this.langKey}
          termsUrl={this.termsUrl}
        />
      </Layout>
    );
  }
}

export default isAuth0Enabled
  ? withAuthenticationRequired(SignUpPage, {
      loginOptions: {
        authorizationParams: {
          screen_hint: "signup",
          ui_locales: "zh-CN",
        },
      },
    })
  : SignUpPage;
